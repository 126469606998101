
h1 {
    font-weight: 700;
  }
  
  p,
  a {
    font-family: 'Montserrat', sans-serif;
  }
  
  a,
  a:link,
  a:hover,
  a:visited,
  a:active {
    text-decoration: none;
  }
  
  a:hover {
    transition: all 0.3s ease-in-out;
  }
  
  .section-title {
    margin: 0px;
    margin-bottom: 4.5rem;
    font-weight: 1;
    text-transform: uppercase;
    
    
  }
  .section-category-title {
    margin: 0px;
    margin-bottom: 4.5rem;
    font-weight: 1;
    text-transform: uppercase;
    color: #ffffff;
    
  }
  