.footer-icon{
    color: #fff;
    cursor: pointer;
    font-size: 4rem;
    margin-right: 20px;
	transition:all 1s;
    &:hover{
        transform: scale(0.9);
        filter: blur(2px);
        color: rgba(255, 255, 255, 0.281);
    }
}
.footer-icon-container{
    display: flex;
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    height: 15rem;
}
.footer-text{
    position: absolute;
    right: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 2.0rem;
    margin-right: 20px;
	transition:all 1s;
    &:hover{
        transform: scale(0.9);
        filter: blur(2px);
        color: rgba(255, 255, 255, 0.281);
    }
}
.footer-fade-class{
    width: fit-content;
}