.App{
  width: 100%;
  height: 100vh;
  
  background: url('images/Desktop.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  background: url('images/Desktop.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  /* -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}



::-webkit-scrollbar-track{
background-color: transparent !important;
}  /* margin-right: 10px !important; */

:root{
  --bs-nav-link-font-size: 20px;
}