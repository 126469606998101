/* Call to Action Button */
.cta-btn {
  display: inline-block;
  position: relative;
  padding: 0.8rem ;
  font-weight: bold;
  line-height: 1;
  z-index: 1;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s !important;

  &::after {
    content: '';
    color: black  !important;
    display: block;
    position: absolute;
    width: 0px;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  }
  &:hover{
    color: black  !important;

  }
}


/* Resume Style */
.cta-btn--resume {
  color: #ffffff;
  border: 1px solid #ffffff;
  font-size: 2.4rem;
  background-color: transparent;
  &::after {
    color: black  !important;
    background-image: linear-gradient(205deg, rgba(255,255,255,0.5), rgba(255,255,255,0.5));
    // Responsive Button hero style

  }

  &:hover {
    cursor: pointer;
    -webkit-text-fill-color: rgba(255,255,255,0.5);
    text-decoration: none;
    // Responsive Button hover hero style
 

    &::after {
      width: 100%;
    }
  }
}

/* Arrow Button */
.up i {
  color: #272727;
}

.cta-btn--prototype {
  margin: 0 auto !important;
  background-image: linear-gradient(205deg, #ffffff 10%,#ffffff 20%) !important;
  -webkit-background-clip: text !important;
  // -webkit-text-fill-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(205deg, black, black);
  border-image-slice: 1;
  color: black !important;
  cursor: pointer;
  // Responsive Button hero style
  

  &::after {
    color: black  !important;
    background-image: linear-gradient(205deg, rgba(47, 47, 47,0.3), rgba(47, 47, 47,0.3)) !important;
    // Responsive Button hero style
  
  }

  &:hover {
    -webkit-text-fill-color: black !important;
    text-decoration: none;
    // Responsive Button hover hero style
   

    &::after {
      width: 100%;
    }
  }
}
