.project-title{
    display: inline-block;
    font-size: 4rem;
    font-weight: 1;
    text-align: center;
    // margin-top: 10px;
    margin-right: 30px;
    font-family: 'Tajawal';
}

.project-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  height: 35px;
}

.project-title-container{
  margin-top: 10px;
  width: fit-content;
  display: flex;
  align-items: center; 
}
.project-sub-header{
  font-size: 2.5rem;
  font-weight: bold;
  font-family: 'Tajawal';
  text-align: left;
}

.project-container{
    // padding-top: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // height: 900px  !important;
    // height: 600px;
    // overflow-y: scroll;
}
.project-container-blay{
    // padding-top: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // height: 900px  !important;
    // height: 600px;
    // overflow-y: scroll;}
}
.project-text{
    text-align: left;
    font-size: 1.8rem;
    font-family: 'Tajawal';
}

.project-text-bold{
  font-weight: bold !important;
}

.project-text-container{
    margin: 0 auto;
    width: 70%;
    min-width: 600px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
}
.project-image{
    margin: 0 auto;

}
::-webkit-scrollbar {
    width: 4px;
    border-radius: 15px;
    right: 5px !important;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

  #projects {
    background-color: black;
    color: black;
    margin-top: -15rem;
    padding-top: 15rem;
    width: 70%;
    margin: 0 auto;

    .project-wrapper {
      margin-bottom: 15rem;
    
      .row {
        background-color: rgb(224, 224, 224);
        padding: 20px;
        margin-bottom: 3rem;
        border-radius: 20px;
        // width: 70%;
      }
      &__text {
        text-align: left;
  
        &-title {
          font-weight: 1;
          margin-bottom: 1.8rem;
  
        }

      }
  
      &__image {
        width: 70%;
        margin: 0 auto;
        margin-right: 0px;
     
        & .thumbnail {
          border: none;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          transition: all 0.2s ease-out;
          box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
          transition: 0.5s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.5s box-shadow,
            0.5s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  
        
        }
      }
    }
  }
  .thumbnail-rounded{
    width: fit-content;
    margin: 0 auto;
  }
